import Storage from './storage';

enum Locals {
  ACCESS_TOKEN = 'access_token',
  MERCHANT_ID = 'merchant_id',
  ENVIRONMENT = 'environment'
}

export default class Tokens extends Storage<Locals> {
  private static instance?: Tokens;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Tokens();
    }

    return this.instance;
  }

  public getAccessToken() {
    return this.get(Locals.ACCESS_TOKEN);
  }

  public setAccessToken(accessToken: string) {
    this.set(Locals.ACCESS_TOKEN, accessToken);
  }

  public getMerchantId() {
    return this.get(Locals.MERCHANT_ID);
  }

  public setMerchantId(merchantId: string) {
    this.set(Locals.MERCHANT_ID, merchantId);
  }

  public getEnvironment() {
    return this.get(Locals.ENVIRONMENT);
  }

  public setEnvironment(environment: string) {
    this.set(Locals.ENVIRONMENT, environment);
  }

  public clear() {
    this.clearItems([Locals.ACCESS_TOKEN, Locals.MERCHANT_ID, Locals.ENVIRONMENT]);
  }
}

