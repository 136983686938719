import { fetchAndProcess } from "./utils";
import { keyable } from "./types";
import Tokens from "../utils/tokens";

const tokens = Tokens.getInstance();

let API_PROD_HOST = "https://api.cyanbot.com";
let API_STAGING_HOST = "https://api.cyanbot.com";
// let API_STAGING_HOST = "http://localhost:8000";

type OrderResponse = {
  page: number;
  n_results: number;
  items_per_page: number;
  orders: keyable[];
};

async function getOrders(): Promise<OrderResponse> {
  let API_HOST =
    tokens.getEnvironment() === "staging" ? API_STAGING_HOST : API_PROD_HOST;

  let merchantId = tokens.getMerchantId();
  if (!merchantId) {
    merchantId = "";
  }
  return fetchAndProcess(
    `${API_HOST}/v2/orders/?` +
      new URLSearchParams({
        state: "unassigned,at_merchant,going_to_merchant",
        merchant_id: merchantId,
      }),
    (data: OrderResponse) => {
      for (let i = 0; i < data.orders.length; i++) {
        if (data.orders[i].order_type === "storefront") {
          data.orders[i].order_total = data.orders[i].total_price;
        }
        if (data.orders[i].order_total) {
          let total = Number(data.orders[i].order_total / 100).toFixed(2);
          data.orders[i].order_total = `\$${total}`;
        }
      }

      // Filter out hand deliveries
      let filteredOrders = [];
      if (data.orders) {
        for (const o of data.orders) {
          if (o.delivery_method !== "robot") {
            console.log("Ignoring " + o.id);
          } else {
            filteredOrders.push(o);
          }
        }
        data.orders = filteredOrders;
      }

      return data;
    },
    (err: string) => {
      console.log(err);
    }
  );
}

async function updateOrder(
  orderId: string | null,
  update: keyable
): Promise<void> {
  let API_HOST =
    tokens.getEnvironment() === "staging" ? API_STAGING_HOST : API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/v2/orders/${orderId}`,
    (response) => {
      return response;
    },
    (err: string) => {
      console.log(err);
    },
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update),
    }
  );
}

async function closeCoco1Lid(robotId: string) {
  // Purposefully, setting this to always use production in case we forget to update dev DB
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/robots/${robotId}/lock`,
    () => {},
    (err: string) => {
      console.log(err);
    },
    {
      method: "POST",
    }
  );
}

async function updateOrderState(
  orderId: string | null,
  state: string
): Promise<void> {
  let API_HOST =
    tokens.getEnvironment() === "staging" ? API_STAGING_HOST : API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/v2/orders/${orderId}/state`,
    () => {},
    (err: string) => {
      console.log(err);
    },
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ new_state: state }),
    }
  );
}

async function unlockRobot(robotId: string): Promise<void> {
  // Purposefully, setting this to always use production in case we forget to update dev DB
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/robots/${robotId}/unlock`,
    () => {},
    (err: string) => {
      console.log(err);
    },
    {
      method: "POST",
    }
  );
}

async function login(email: string, password: string): Promise<keyable> {
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/auth/login`,
    (data) => {
      if (data.access_token) {
        // TODO(Brad): Currently using "tokens" as a generalized storage
        //             library. This is kind of confusing
        tokens.setAccessToken(data.access_token);
        tokens.setMerchantId(data.user.merchant_id);
      }
      return data;
    },
    (err: string) => {
      console.log("Error: ", err);
    },
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    }
  );
}

async function getRobot(robotId: string): Promise<keyable> {
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/robots/${robotId}`,
    (data) => {
      console.log("Data2", data);
      return data;
    },
    (err: string) => {
      console.log(err);
    }
  );
}

async function getRobotByName(robotName: string): Promise<keyable> {
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/robots/?name=${robotName}`,
    (data) => {
      console.log("Data1", data);
      if (data.total_results > 0) {
        return data.robots[0];
      }
    },
    (err: string) => {
      console.log(err);
    }
  );
}

async function placeTestOrder(): Promise<void> {
  let API_HOST = API_STAGING_HOST;

  let merchantId = tokens.getMerchantId();

  let order = {
    order_type: "delivery_api",
    destination: {
      address1: "2671 Main Street",
      address2: "Santa Monica, CA 90405",
      suite: "Suite 102",
      location: [-118.48255095331037, 34.00117103884927],
    },
    merchant_id: merchantId,
    third_party_order_id: "DOORDASH123",
    customer_name: "Coco R.",
    customer_phone: "7325476925",
    order_total: "8344",
  };

  return fetchAndProcess(
    `${API_HOST}/v2/orders/place`,
    () => {},
    (err: string) => {
      console.log(err);
    },
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    }
  );
}

async function getMerchant(merchantId: string): Promise<keyable> {
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/merchants/${merchantId}`,
    (data) => data,
    (err: string) => console.log("Error getting merchant: ", err)
  );
}

async function getMerchantByUserEmail(username: string): Promise<keyable> {
  let API_HOST = API_PROD_HOST;

  return fetchAndProcess(
    `${API_HOST}/merchants/user/${username}`,
    (data) => data,
    (err: string) => console.log("Error getting merchant: ", err)
  );
}

export {
  getOrders,
  updateOrder,
  updateOrderState,
  unlockRobot,
  login,
  getRobot,
  getRobotByName,
  placeTestOrder,
  closeCoco1Lid,
  getMerchant,
  getMerchantByUserEmail,
};
