import React from "react";
// import { Route, BrowserRouter as Router } from "react-router-dom";
// import Store from "./state/Store";
// import PrivateRoute from "./components/PrivateRoute";
// import Orders from "./containers/Orders";
// import Login from "./containers/Login";
// import { MerchantCheck } from "./containers/MerchantCheck";
// import RobotLandingPage from "./containers/RobotLandingPage";
// import SelectedOrder from "./containers/SelectedOrder";
// import SuccessPage from "./containers/SuccessPage";
import "./index.css";

function App() {
  window.location.assign(
    "https://merchant-qr.delivery-dev.cocodelivery.com" +
      window.location.pathname
  );

  return (
    <div></div>
    // <Store>
    //   <div className="App">
    //     <Router>
    //       <Route path="/check" component={MerchantCheck} exact />
    //       <Route path="/login" component={Login} exact />
    //       <Route path="/robots/:robot_id" component={RobotLandingPage} />
    //       <PrivateRoute path="/" component={Orders} exact />
    //       <PrivateRoute path="/order/:order_id" component={SelectedOrder} />
    //       <PrivateRoute path="/success" component={SuccessPage} exact />
    //     </Router>
    //   </div>
    // </Store>
  );
}

export default App;
