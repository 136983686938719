import createAuthRefreshInterceptor from 'axios-auth-refresh';

import Tokens from './tokens';
import axios, {AxiosResponse} from 'axios';

const instance = axios.create();
const tokens = Tokens.getInstance();

// TODO(Brad): Convert to typescript
const refreshAuthLogic = async (
  failedRequest: any
) => {
  console.log(
    `401 - refreshAuthLog: ${JSON.stringify(failedRequest.response.data)}`,
  );
  const splitUrl = failedRequest.config.url.split('/');
  const baseUrl = `${splitUrl[0]}//${splitUrl[2]}`;
  try {
    const tokenRefreshResponse = await axios.get(`${baseUrl}/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${await tokens.getAccessToken()}`,
      },
    });
    console.log('Refreshed auth token successfully!');

    const accessToken = tokenRefreshResponse.data.access_token;
    await tokens.setAccessToken(accessToken);
    failedRequest.response.config.headers.Authorization =
      'Bearer ' + tokenRefreshResponse.data.access_token;
  } catch (err) {
    console.log('Failed refreshing token', err);

    // (Brad): This is kinda weird. Not sure best way to do this.
    // This is to handle if the client HAS a token but it cannot be refreshed.
    // It will clear and reload. It saves prevToken to avoid infinite loop.
    let prevToken = tokens.getAccessToken();
    await tokens.clear();
    if (prevToken) {
      window.location.reload();
    }
  }
  return Promise.resolve();
};

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;