import axios from "./axios";
import Tokens from "./tokens";
import Storage from "./storage";
import { keyable } from "./types";

const tokens = Tokens.getInstance();

type json = {
  [key: string]: any;
};

async function fetchAndProcess(
  url: string,
  handler: (value: any) => any,
  onError: (value: any) => any,
  options?: json
): Promise<any> {
  const finalOptions = { ...options };
  if (finalOptions.body) {
    finalOptions.data = finalOptions.body;
  }

  finalOptions.headers = finalOptions.headers || {};
  finalOptions.headers.Authorization = `Bearer ${await tokens.getAccessToken()}`;

  return axios(url, finalOptions)
    .then((response: any) => {
      return response.data;
    })
    .then(handler)
    .catch((error: any) => {
      let finalError = error;
      if (error && error.request && error.request._response) {
        try {
          finalError = JSON.parse(error.request._response).message;
        } catch (e) {
          finalError = error.request._response;
        }
      }

      console.log("Fetch and process failed with:", finalError);

      if (onError) {
        onError(finalError);
      }
    });
}

function parseRobotIdFromQRCode(data: string): keyable | null {
  if (data.length === 6 && data.toLowerCase().startsWith("c1")) {
    return {
      type: "name",
      data: data,
    };
  } else {
    return parseRobotIdFromUrl(data);
  }
}

function parseRobotIdFromUrl(url: string): keyable | null {
  let splitUrl = url.split("/");

  if (splitUrl.length === 5) {
    let prefix = splitUrl[splitUrl.length - 2];
    if (prefix === "robots") {
      // Robot Id type
      let robotId = splitUrl[splitUrl.length - 1];
      if (robotId.length === 24) {
        return {
          type: "id",
          data: robotId,
        };
      } else if (robotId.length === 3 && robotId[0].toLowerCase() === "d") {
        return {
          type: "name",
          data: robotId,
        };
      } else if (
        robotId.length === 6 &&
        robotId.toLowerCase().startsWith("c1")
      ) {
        return {
          type: "name",
          data: robotId,
        };
      }
    }
  }
  console.log("Returned nothing for url: ", url);
  return null;
}

enum Locals {
  SCANNED_ROBOT = "scanned_robot",
}

export default class LocalStorage extends Storage<Locals> {
  private static instance?: LocalStorage;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new LocalStorage();
    }

    return this.instance;
  }

  public get(key: Locals): string {
    return this.get(key);
  }

  public set(key: Locals, value: string) {
    this.set(key, value);
  }

  public clear() {
    this.clearItems([Locals.SCANNED_ROBOT]);
  }
}

export { fetchAndProcess, parseRobotIdFromUrl, parseRobotIdFromQRCode };
